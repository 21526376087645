
<template>
    <b-container fluid>
      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-col xs="12" sm="6" md="5" lg="5" xl="5">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.fiscal_year')"
              label-for="fiscal_year_id"
              >
              <b-form-select
              plain
              v-model="search.fiscal_year_id"
              :options="fiscalYearList"
              id="fiscal_year_id"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </template>
      </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.demand_collection_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(mobile_no_bn)="data">
                                            {{ '০' + $n(data.item.mobile_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(nid_no_bn)="data">
                                            {{ $n(data.item.nid_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_land_bn)="data">
                                            {{ $n(data.item.total_land, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          {{ getStatus(data.item.status) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-if="data.item.status === 1" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                            <!-- <b-button v-if="data.item.status === 1" class="mr-1" title="Final Save" v-b-modal.modal-1 variant=" iq-bg-success" size="sm" @click="finalsave(data.item)"><i class="ri-check-line"></i></b-button> -->
                                            <!-- <b-button class="mr-1" title="Details" v-b-modal.modal-2 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.status === 1" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                              <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.status === 1" title="Final Save" v-b-modal.modal-1 size="sm" @click="finalsave(data.item)">
                                              <i class="fas fa-check"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-2 size="sm" @click="details(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-1" size="md" :title="$t('bsri_demandDIS.finalsavemsg')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Finalsave :id="itemId" :key="itemId"/>
        </b-modal>
        <b-modal id="modal-2" size="xl" :title="$t('bsri_demandDIS.demand_collection_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Finalsave from './Finalsave'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { demandCollectionList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Finalsave, Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: '0',
        unit_id: 0
      },
      rows: [],
      item: '',
      itemId: 0
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_demandDIS.demeand_collection_name_add') : this.$t('bsri_demandDIS.demeand_collection_name_edit') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.fiscal_year'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.farmer_name'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.mobile_number'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.nid_number'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.total_land_quantity'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'name_bn' },
          { key: 'mobile_no_bn' },
          { key: 'nid_no_bn' },
          { key: 'total_land_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_en' },
          { key: 'name' },
          { key: 'mobile_no' },
          { key: 'nid_no' },
          { key: 'total_land' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  // watch: {
  //   loadingState: function (newVal, oldVal) {
  //     if (newVal) {
  //       this.loadData()
  //     }
  //   }
  // },
  created () {
    this.search.unit_id = this.$store.state.Auth.authUser.office_id
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    finalsave (item) {
      this.itemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, demandCollectionList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const listData = data.map(item => {
        const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const fiscalYearData = { fiscal_year_en: fiscalYearObj.text_en, fiscal_year_bn: fiscalYearObj.text_bn }
        return Object.assign({}, item, fiscalYearData)
      })
      return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('bsri_demandDIS.draft')
      } else if (status === 2) {
        return this.$t('bsri_demandDIS.finalsave')
      } else if (status === 3) {
        return this.$t('bsri_demandDIS.forward')
      } else if (status === 4) {
        return this.$t('bsri_demandDIS.approve')
      } else if (status === 5) {
        return this.$t('bsri_demandDIS.reject')
      }
    }
  }
}
</script>
