<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmers_nid"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_nid_number')}}
                                                    </template>
                                                        <p class="mt-2 mb-0">{{ $n(demandCollection.nid_no, { useGrouping: false }) }}</p>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="fiscal_year_id"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.fiscal_year') }}
                                                        </template>
                                                        <p class="mt-1 mb-0"> {{ $i18n.locale === 'bn' ? farmersInfo.fiscal_year_bn : farmersInfo.fiscal_year_en }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_name"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_name')}}
                                                        </template>
                                                            {{ farmersInfo.name }}
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_father_name"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_father_name')}}
                                                        </template>
                                                            {{ farmersInfo.father_name }}
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_mother_name"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_mother_name')}}
                                                        </template>
                                                            {{ farmersInfo.mother_name }}
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_dob"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.farmer_dob')}}
                                                            </template>
                                                            {{ farmersInfo.date_of_birth | dateFormat }}
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_gender"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_demandDIS.farmer_gender') }}
                                                        </template>
                                                        <p class="mt-1 mb-0">{{ farmersInfo.gender_name }}</p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_blood_group"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('bsri_demandDIS.farmer_blood_group') }}
                                                            </template>
                                                            <p class="mt-1 mb-0">{{ farmersInfo.blood_group_name }}</p>
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_mobile_number"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.mobile_number')}}
                                                        </template>
                                                            {{ $i18n.locale === 'bn' ? 'o' : '0' }}{{ $n(farmersInfo.mobile_no, { useGrouping: false }) }}
                                                        </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 pl-2">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_present_address') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_district"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_district')}}
                                                    </template>
                                                        {{ farmersInfo.district_name_pre }}
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_upazilla"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_upazilla')}}
                                                    </template>
                                                        {{ farmersInfo.upazilla_name_pre }}
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_union"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_union')}}
                                                    </template>
                                                        {{ farmersInfo.union_name_pre }}
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_ward"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_ward')}}
                                                    </template>
                                                        {{ farmersInfo.word_name_pre }}
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_village"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_village')}}
                                                    </template>
                                                        {{ farmersInfo.village_name_pre }}
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_block')}}
                                                    </template>
                                                        {{ farmersInfo.block_name_pre }}
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3  pl-2">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_permanent_address') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_district')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.district_name_per }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_upazilla')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.upazilla_name_per }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_union')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.union_name_pre }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_ward')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.word_name_pre }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_village')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.village_name_pre }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="farmer_present_block"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.farmer_block')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.block_name_pre }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3  pl-2">
                                                            <h5 class="text-white"> {{ $t('common_config.account_info') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="account_type_name"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('common_config.account_info')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.account_type_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="far_wallet_no"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('common_config.far_wallet_no')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ $n(farmersInfo.wallet_no, { useGrouping: false }) }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="account_type"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('common_config.account_type')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.ac_type_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="account_no"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('common_config.account_no')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ $n(farmersInfo.account_no, { useGrouping: false }) }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="bank_name"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.bank')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.bank_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="branch"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_demandDIS.branch')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.branch_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                 <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3  pl-2">
                                                            <h5 class="text-white"> {{ $t('common_config.Office_mapping') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="mill_name"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.mill_name')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.mill_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="subzone_office_name"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.subzone_office_name')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.subzone_office_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="center_name"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.center_name')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.center_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="unit_name"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.unit_no')}}
                                                    </template>
                                                        <p class="mt-1 mb-0"> {{ farmersInfo.unit_name }} </p>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 pl-2">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.land_information') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <b-table-simple striped bordered small class="mt-2">
                                                    <b-thead>
                                                        <tr>
                                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th>{{ $t('bsri_demandDIS.method_of_cultivation') }}</b-th>
                                                            <b-th>{{ $t('bsri_demandDIS.quantity_acar') }}</b-th>
                                                        </tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                        <template v-if="demandCollection.details.length">
                                                            <b-tr v-for="(item, index) in demandCollection.details" :key="index">
                                                                <b-td  class="text-center">{{ index+1 }}</b-td>
                                                                <b-td class="text-left">{{ locale === 'en' ? item.cultivation_method_name : item.cultivation_method_name_bn }}</b-td>
                                                                <b-td class="text-left">{{ $n(item.total_land, { useGrouping: false }) }}</b-td>
                                                            </b-tr>
                                                        </template>
                                                        <template v-else>
                                                            <b-tr>
                                                                <b-td colspan="3" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                            </b-tr>
                                                        </template>
                                                    </b-tbody>
                                                    <b-tfoot>
                                                        <b-tr>
                                                            <b-td colspan="2" align="right">{{ $t('bsri_demandDIS.total') }}</b-td>
                                                            <b-td align="left">{{ $n(grandTotal, { useGrouping: false }) }}</b-td>
                                                        </b-tr>
                                                    </b-tfoot>
                                                </b-table-simple>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver } from 'vee-validate'

export default {
    props: ['id'],
    components: {
        ValidationObserver
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            onlyCreate: true,
            demandCollection: {
                nid_no: '',
                fiscal_year_id: 0,
                far_general_info_id: '',
                cda_id: 1,
                details: []
            },
            farmer_nid_number: '',
            farmersInfo: [],
            msg: '',
            grandTotal: 0,
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ]
        }
    },
    created () {
        if (this.id) {
            this.onlyCreate = false
            const tmp = this.getEditingData()
            this.demandCollection = tmp
            const tmpTwo = this.getReadOnlyData()
            this.farmersInfo = tmpTwo
            this.getGrandTotal()
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        bloodGroupList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'O-' : 'ও−' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'A-' : 'এ−' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'B-' : 'বি−' },
                { value: 6, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
                { value: 7, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি−' },
                { value: 8, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' }
            ]
           return list
        },
        fiscalYearList: function () {
            const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalYearList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        cultivationMethodList: function () {
            const cultMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
            return cultMethodList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getReadOnlyData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const farDetails = this.getRelatinalData(tmpData)
            this.demandCollection.details = this.clutivationMethodData(tmpData)
            return JSON.parse(JSON.stringify(farDetails))
        },
        getRelatinalData (data) {
            const districtObj = this.$store.state.commonObj.districtList.find(district => district.value === data.district_id)
            const districtData = { district_name_pre: districtObj !== undefined ? (this.locale === 'bn' ? districtObj.text_bn : districtObj.text_en) : '' }
            const districtObjPer = this.$store.state.commonObj.districtList.find(district => district.value === data.per_district_id)
            const districtDataPer = { district_name_per: districtObjPer !== undefined ? (this.locale === 'bn' ? districtObjPer.text_bn : districtObjPer.text_en) : '' }
            const upazillaObj = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === data.upazilla_id)
            const upazillaData = { upazilla_name_pre: upazillaObj !== undefined ? (this.locale === 'bn' ? upazillaObj.text_bn : upazillaObj.text_en) : '' }
            const upazillaObjPer = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === data.per_upazilla_id)
            const upazillaDataPer = { upazilla_name_per: upazillaObjPer !== undefined ? (this.locale === 'bn' ? upazillaObjPer.text_bn : upazillaObjPer.text_en) : '' }
            const unionObj = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const unionData = { union_name_pre: unionObj !== undefined ? (this.locale === 'bn' ? unionObj.text_bn : unionObj.text_en) : '' }
            const unionObjPer = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const unionDataPer = { union_name_per: unionObjPer !== undefined ? (this.locale === 'bn' ? unionObjPer.text_bn : unionObjPer.text_en) : '' }
            const wardObj = this.$store.state.commonObj.wardList.find(ward => ward.value === data.ward_id)
            const wardData = { word_name_pre: wardObj !== undefined ? (this.locale === 'bn' ? wardObj.text_bn : wardObj.text_en) : '' }
            const wardObjPer = this.$store.state.commonObj.wardList.find(ward => ward.value === data.per_ward_id)
            const wardDataPer = { word_name_per: wardObjPer !== undefined ? (this.locale === 'bn' ? wardObjPer.text_bn : wardObjPer.text_en) : '' }

            const villageData = { village_name_pre: (this.locale === 'bn' ? data.village_bn : data.village_en) }
            const villageDataPer = { village_name_per: (this.locale === 'bn' ? data.per_village_bn : data.per_village_en) }
            const blockData = { block_name_pre: (this.locale === 'bn' ? data.block_bn : data.block_en) }
            const blockDataPer = { block_name_per: (this.locale === 'bn' ? data.per_block_bn : data.per_block_en) }

            const genderObj = this.$store.state.commonObj.genderList.find(block => block.value === data.gender_id)
            const genderData = { gender_name: genderObj !== undefined ? (this.locale === 'bn' ? genderObj.text_bn : genderObj.text_en) : '' }
            const bloodGroup = this.bloodGroupList.find(bloodSingleGroup => bloodSingleGroup.value === data.blood_group_id)
            const bloodGroupData = { blood_group_name: bloodGroup !== undefined ? bloodGroup.text : '' }
            const accountTypeData = this.accountTypeList.find(type => type.value === data.ac_category_id)
            const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
            const farmerName = { name: this.locale === 'bn' ? data.name_bn : data.name }
            const fatherName = { father_name: this.locale === 'bn' ? data.father_name_bn : data.father_name }
            const motherName = { mother_name: this.locale === 'bn' ? data.mother_name_bn : data.mother_name }
            const acTypeList = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === data.ac_type_id)
            const acTypeData = { ac_type_name: acTypeList !== undefined ? (this.locale === 'bn' ? acTypeList.text_bn : acTypeList.text_en) : '' }
            const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === data.bank_id)
            const bankData = { bank_name: bankObj !== undefined ? (this.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
            const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === data.branch_id)
            const branchData = { branch_name: branchObj !== undefined ? (this.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
            const millInfoObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millInfo => millInfo.value === data.mill_info_id)
            const millInfoData = { mill_name: millInfoObj !== undefined ? (this.locale === 'bn' ? millInfoObj.text_bn : millInfoObj.text_en) : '' }
            const subzoneOfficeObj = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.find(subZone => subZone.value === data.subzone_office_id)
            const subzoneOfficeData = { subzone_office_name: subzoneOfficeObj !== undefined ? (this.locale === 'bn' ? subzoneOfficeObj.text_bn : subzoneOfficeObj.text_en) : '' }
            const centerOfficeObj = this.$store.state.incentiveGrant.commonObj.centerList.find(center => center.value === data.center_id)
            const centerData = { center_name: centerOfficeObj !== undefined ? (this.locale === 'bn' ? centerOfficeObj.text_bn : centerOfficeObj.text_en) : '' }
            const unitObj = this.$store.state.incentiveGrant.commonObj.unitList.find(unit => unit.value === data.unit_id)
            const unitData = { unit_name: unitObj !== undefined ? (this.locale === 'bn' ? unitObj.text_bn : unitObj.text_en) : '' }
            return Object.assign({}, data, districtData, districtDataPer, upazillaData, upazillaDataPer, unionData, unionDataPer, wardData, wardDataPer, villageData, villageDataPer, blockData, blockDataPer, genderData, bloodGroupData, farmerName, fatherName, motherName, accountGroupData, bankData, branchData, millInfoData, subzoneOfficeData, centerData, unitData, acTypeData)
        },
        getGrandTotal () {
            let total = 0
            this.demandCollection.details.filter(item => {
                total += parseFloat(item.total_land)
            })
            this.grandTotal = total
        },
        clutivationMethodData (data) {
            const listData = data.details.map(singleItem => {
                const cultivationMethodObj = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(item => item.value === singleItem.cultivation_id)
                const cultivationData = { cultivation_method_name: cultivationMethodObj !== undefined ? cultivationMethodObj.text_en : '', cultivation_method_name_bn: cultivationMethodObj !== undefined ? cultivationMethodObj.text_bn : '' }
                return Object.assign({}, singleItem, cultivationData)
            })
            return listData
        }
    }
}
</script>
