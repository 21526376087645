<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
                <div class="text-center">
                    <b-button type="submit" variant="primary" @click="finalsaved" class="mr-2">{{ $t('bsri_demandDIS.finalsave') }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { demandCollectionFinalSave } from '../../api/routes'

export default {
  props: ['id'],
  data () {
    return {
      loading: false,
      demandinfo: {
        demand_id: ''
      }
    }
  },
  created () {
      this.demandinfo.demand_id = this.id
  },
  methods: {
    async finalsaved () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, demandCollectionFinalSave, this.demandinfo)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        this.loading = false

        if (result.success) {
            this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })

            this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
